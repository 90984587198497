import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
  border-top: solid 1px #000;
  ${({ campaignId }) => `
    &:before {
        content: "CAMPAIGN: ${campaignId}";
        position:absolute;
        top: 8px;
        left: 5px;
        background: blue;
        color: white;
        font-size: 12px;
        padding: 5px 10px 3px;
        font-weight: bold;
        border-radius: 10px 10px 0 0;
        z-index: 9999;
    }`}
`;
