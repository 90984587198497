import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Builder } from '@builder.io/react';
import * as S from './CampaignContainer.styles';

const CampaignContainer = ({ campaignId = 'DEFAULT', children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          current_campaign_id
        }
      }
    }
  `);

  if (Builder.isEditing && Builder.previewingModel === 'banners') {
    return <S.Container campaignId={campaignId}>{children}</S.Container>;
  }

  const currentCampaignId = data.site.siteMetadata.current_campaign_id;
  return currentCampaignId === campaignId ? <>{children}</> : null;
};

export default CampaignContainer;
